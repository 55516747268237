var wipwrp = window.wipwrp || {};


wipwrp.wipParsleyExtensions = (function($) {

	/* ----------------- private vars ------------------*/

	var	self	=	{};

	/* ------- Parsley configs and custom validators ---*/

	_configs();
	_multipleOf();
	_partitaiva();
	_codicefiscale();
	_validValue();
	_cap();
    _iban();
	_ibanNotBancoposta();
	_swift();
	_doubleQuoteFree();

	// riferimento a regex: http://regexr.com/
	
	/* ----------------- public methods ---------------*/

	function _configs(){
		window.ParsleyConfig = {
			errorsWrapper: "<ul class='help-block'></ul>"
			// ,excluded: 'input[type=hidden],textarea[type=hidden],textarea[readonly],select[type=hidden],select[readonly],input[type=button], input[type=submit], input[type=reset], :hidden'
			,excluded: '.novalidate input, .novalidate select, .novalidate textarea, .novalidate checkbox, .novalidate radio, input.novalidate, select.novalidate, textarea.novalidate, checkbox.novalidate, radio.novalidate'
			,errorTemplate: "<li></li>"
			,errorClass:'error'
			,successClass:''
			,validateIfUnchanged: true
			,trigger: 'change'
			,classHandler: function (ParsleyField) {
				return ParsleyField.$element.parents('.control-group');
			}
			,errorsContainer: function (ParsleyField) {
				return ParsleyField.$element.parents('.control-group');
			}
			,validators : {}
			,i18n : {}
		};
	}

	function _cap(){
		window.ParsleyConfig.validators.cap = {
			fn: function (value) {
				var regex  = /\b\d{5}\b/;
				return regex.test(value);
			},
			priority: 32
		}
	}

	function _validValue(){
		window.ParsleyConfig.validators.validvalue = {
			fn: function (value,requirement) {
				return requirement == value;
			},
			priority: 32
		}
	}

	function _multipleOf(){
		window.ParsleyConfig.validators.multipleof = {
			fn: function (value, requirement) {
			  return 0 === value % requirement;
			},
			priority: 32
		}
	}

	function _doubleQuoteFree(){
		window.ParsleyConfig.validators.doublequotefree = {
			fn: function (value) {
				var regex  = /["]/;
				return !regex.test(value);
			},
			priority: 32
		}
	}
	
	function _swift(){
		window.ParsleyConfig.validators.swift = {
			fn: function (value) {
				var regex  = /\b\w{8}\b|\b\w{11}\b/g;
				return regex.test(value);
			},
			priority: 32
		}
	}

    function _iban(){
        window.ParsleyConfig.validators.iban = {
            fn: isIbanValid,
            priority: 32
        }
    }

	function isIbanValid(value){
		iban = value;
		lunghezzaIban = value.length;
		iban = iban.toUpperCase();
		iban = iban.replace(/ /g,'');
		iban = iban.replace(/[^a-zA-Z0-9]+/g,'');
		var daSpostare = iban.substr(0, 4);
		iban = iban.replace(daSpostare,'');
		iban = iban+daSpostare;
		var conversione ={  "A":"10", "B":"11", "C":"12", "D":"13", "E":"14", "F":"15", "G":"16", "H":"17", "I":"18", "J":"19",
			"K":"20", "L":"21", "M":"22", "N":"23", "O":"24", "P":"25", "Q":"26", "R":"27", "S":"28", "T":"29",
			"U":"30", "V":"31", "W":"32", "X":"33", "Y":"34", "Z":"35",};
		var k = iban.length;
		var ibanRicomposto='';
		for(i = 0; i< k; i++){
			if(conversione[iban[i]] === undefined){
				ibanRicomposto = ibanRicomposto+iban[i];
			}else{
				ibanRicomposto = ibanRicomposto+iban[i].replace(iban[i],conversione[iban[i]]);
			}
		}

		if(iso7064Mod97_10(ibanRicomposto) == 1 && lunghezzaIban >= 15){
			return true;
		}
		return false;
	}

    function iso7064Mod97_10(iban) {
        var remainder = iban,
            block;

        while (remainder.length > 2){
            block = remainder.slice(0, 9);
            remainder = parseInt(block, 10) % 97 + remainder.slice(block.length);
        }

        return parseInt(remainder, 10) % 97;
    }

	function _ibanNotBancoposta() {
		window.ParsleyConfig.validators.bancoposta = {
			fn: function (value) {
				if (!isIbanValid(value)){
					return true;
				}
				var iban = value;
				iban = iban.replace(/[^a-zA-Z0-9]+/g,'');
				var regex  = /\b.{5}(?!07601).+\b/g;
				return regex.test(value);
			},
			priority: 32
		}
	}

	function _partitaiva(){
		window.ParsleyConfig.validators.partitaiva = {
			fn: function (value) {
				var result = true;

				if( value.length != 11 && value.length != 13 ) { result = false; }

				value=value.replace('IT','');
				if (value.length == 11) {
					var validi = "0123456789";

					for (i = 0; i < 11; i++) {
						// "La partita IVA non è di sole cifre";
						if (validi.indexOf(value.charAt(i)) == -1) {
							result = false;
						}
					}
					var s = 0;
					for (i = 0; i <= 9; i += 2)
						s += value.charCodeAt(i) - '0'.charCodeAt(0);
					for (i = 1; i <= 9; i += 2) {
						c = 2 * ( value.charCodeAt(i) - '0'.charCodeAt(0) );
						if (c > 9)  c = c - 9;
						s += c;
					}
					if (( 10 - s % 10 ) % 10 != value.charCodeAt(10) - '0'.charCodeAt(0)) {
						result = false;
						// "il codice di controllo non corrisponde.";
					}
				}else{
					if (/[^a-zA-Z]/.test(value.substr(0,2))){
						result = false;
					}
				}
				return result;
			},
			priority: 32
		}
	}


	function _codicefiscale(){
		window.ParsleyConfig.validators.codicefiscale = {
			fn: function (value) {
				var result = true;
				var validi, i, s, set1, set2, setpari, setdisp;

				var	value = value.toUpperCase();
				if( value.length != 16 ) { result = false; }

				validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
				for( i = 0; i < 16; i++ ){
					// caratteri non validi
					if( validi.indexOf( value.charAt(i) ) == -1 ){ result = false }
				}

				set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
				set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
				setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
				setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
				s = 0;
				for( i = 1; i <= 13; i += 2 )
					s += setpari.indexOf( set2.charAt( set1.indexOf( value.charAt(i) )));
				for( i = 0; i <= 14; i += 2 )
					s += setdisp.indexOf( set2.charAt( set1.indexOf( value.charAt(i) )));
				if( s%26 != value.charCodeAt(15)-'A'.charCodeAt(0) ) {
					// non corrisponde il codice di controllo
					// testo se ci hanno messo una piva:
					if(!window.ParsleyConfig.validators.partitaiva.fn(value)) {
						result = false;
					} else {
						result = true;					
					}
				}
				return result;
			},
			priority: 32
		}
	}
	return self;

})(jQuery);